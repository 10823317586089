<template>
  <div>
    <router-link class="come-back" to="/admin/news_comments/">
      <div></div>
      Вернуться назад
    </router-link>

    <h2 class="title_categories">
      {{ isEdit ? `Редактирование комментария ${this.$route.params.commentId} новости ${this.$route.params.id}`
      : 'Создание комментария новости' }}
    </h2>
    <tricolor-line class="card_containet__tricolor"/>

    <form @submit.prevent="createNewsComment" ref="createNewsComment">
      <div>

        <div class="content-sb">
          <p>Автор<span>*</span></p>
          <select name="author" v-model="newsCommentData.author" class="input blue" v-if="!isEdit">
            <option v-for="(member,index) in getMembers" :value="member.id" :key="`member${index}`">
              {{ member.full_name }}
            </option>
          </select>
          <input v-else type="text" disabled class="input blue" v-model="editAuthorFullName">
        </div>

        <!--<div class="content-sb">
          <p>Заголовок<span>*</span></p>
          <input required placeholder="Введите заголовок" v-model="newsCommentData.title" type="text" name="title" class="input blue">
        </div>-->

        <div class="content-sb">
          <p>Текст<span>*</span></p>
          <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="newsCommentData.text" class="wysiwyg"/>
        </div>

        <div class="content-sb">
          <p>Текст (Англ.)</p>
          <ckeditor :config="$editorConfigEng" :editor-url="$root.editorUrl" v-model="newsCommentData.text_eng" class="wysiwyg"/>
        </div>

        <div class="content-sb">
          <p>Дата<span>*</span></p>
          <input required placeholder="Введите дату" v-model="newsCommentData.create_date" type="datetime-local" name="create_date" class="input blue" step="1">
        </div>

        <div class="content-sb">
          <p>Статус<span>*</span></p>
          <select name="status" v-model="newsCommentData.status" class="input blue">
            <option v-for="status in getCommentStatuses" :value="status.id" :key="`statusOption${status.id}`">
              {{ status.title }}
            </option>
          </select>
        </div>

      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/news_comments" class="btn-white">Отмена</router-link>

        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'CreateNewsComment',

  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      editAuthorFullName: null,
      newsCommentData: {
        title: null,
        text: '',
        text_eng: '',
        create_date: null,
        author: 1,
        status: 1
      }
    }
  },

  watch: {
    getDetailNewsComment () {
      const dataFromServer = this.$store.getters.getDetailNewsComment
      console.log(1)

      this.newsCommentData = {
        title: dataFromServer.title,
        title_eng: dataFromServer.title_eng,
        text: dataFromServer.text,
        text_eng: dataFromServer.text_eng ?? '',
        create_date: dataFromServer.create_date.split(' ').join('T'),
        author: dataFromServer.author,
        status: dataFromServer.status
      }

      this.editAuthorFullName = dataFromServer.author_model.full_name

      this.$forceUpdate()
    }
  },

  created () {
    if (this.isEdit) {
      this.$store.dispatch('getDetailNewsComment', this.$route.params.commentId)
    } else {
      this.$store.dispatch('setAllMembers')
    }
    this.$store.dispatch('getCommentStatuses')
  },

  methods: {

    createNewsComment () {
      const formData = new FormData()

      for (const key in this.newsCommentData) {
        formData.append(key, this.newsCommentData[key])
      }

      console.debug(...formData)

      if (!this.isEdit) {
        this.$store.dispatch('createNewsComment', {
          id: this.$route.params.id,
          data: formData
        })
      } else {
        formData.append('_method', 'PUT')
        this.$store.dispatch('updateNewsComment', {
          id: this.$route.params.commentId,
          newsId: this.$route.params.id,
          data: formData
        })
      }
    },

    setFile (file, key) {
      if (key.length === 1) {
        this[key[0]] = file
      } else if (key.length === 2) {
        this[key[0]][key[1]] = file
      }
    }

  },
  computed: {

    getMembers () {
      return this.$store.getters.getMembers
    },

    getCommentStatuses () {
      return this.$store.getters.getCommentStatuses
    },

    getDetailNewsComment () {
      return this.$store.getters.getDetailNewsComment
    }

  },
  components: {
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .wysiwyg {
    width: 33.75rem;
  }

  .btns-create-form {
    border-top: 1px solid #D5D6D9;
    padding-top: 2.5rem;
    margin-top: 2.5rem;
  }

  .content-sb {
    margin-bottom: 1.25rem;

    span {
      color: red;
    }
  }

  .select, .input {
    width: 33.75rem;
  }

  .inputs__container {
    margin-bottom: 2rem;
    border-bottom: 1px solid #D5D6D9;
  }

  .content-blocks__container {
    display: flex;
    flex-direction: column;

    > .content-block {
      margin-bottom: 1.875rem;

      header {
        margin-bottom: .75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h4 {
          font-size: .875rem;
          line-height: 1.375rem;
          color: $mainFontColor;
        }

        > img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
        }
      }

      .data-card {
        background: white;
        border: 1px solid #EBF3FF;
        box-sizing: border-box;
        box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.15);
        border-radius: .5rem;
        padding: 1.25rem;
        width: 100%;
        min-height: 8.875rem;
      }
    }
  }

  .add-content-block__container {
    background-color: $neutral-placeholder;
    width: 100%;
    padding: 1.25rem;
    border-radius: .187rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: .5rem;

    &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $neutral-placeholder;
      top: 0;
      left: 50%;
      transform: translate(-50%,-50%) rotate(45deg);
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    > .btn-blue > img {
      width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
      object-position: center;
      margin-right: .5rem;
    }
  }
</style>
